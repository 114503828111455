$(".variants-thumbnail-list label img").waitUntilExists(function () {
  $(".variants-thumbnail-list label").on("click", function () {
    $(".gallery-mobile").find(".owl-nav button").addClass("disabled");

    setTimeout(function () {
      var $gallery = $(".gallery-mobile"),
        $stage = $(".owl-stage", $gallery),
        $items = $(".owl-item", $stage),
        gallerySize = $stage.parent().width() - 40;

      var $owl = $gallery.owlCarousel({
        loop: false,
        nav: true,
        dots: false,
        autoplay: false,
        items: 1,
      });

      $owl.trigger("refresh.owl.carousel");

      if ($items.length > 1) {
        $stage.css({ width: gallerySize * ($items.length + 1) });
        $stage.parent().find(".owl-next").removeClass("disabled");
        $(".active-mouse-hover", $gallery).remove();
      } else {
        $stage.css({ width: gallerySize + 40 });
        $gallery.find(".owl-nav button").addClass("disabled");
        $gallery.append("<div class='active-mouse-hover'></div>");
      }
    }, 500);
  });
});
