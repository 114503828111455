var changePieces = function (type) {
  var $inputPieces = $(".input-number-pieces input");
  var minPieces = parseInt($inputPieces.attr("min"));
  var maxPieces = parseInt($inputPieces.attr("max"));
  var $addPieces = $(".button-add-pieces");
  var $removePieces = $(".button-remove-pieces");

  var valuePieces = parseInt($inputPieces.val());

  if (type === "add") {
    valuePieces++;
    $removePieces.prop("disabled", false);
  }

  if (type === "remove") {
    valuePieces--;
    $addPieces.prop("disabled", false);
  }

  if (valuePieces < minPieces + 1) $removePieces.prop("disabled", true);
  if (valuePieces > maxPieces - 1) $addPieces.prop("disabled", true);

  $inputPieces.val(valuePieces);
};

$(document).ready(function () {
  var $addPieces = $(".button-add-pieces");
  var $removePieces = $(".button-remove-pieces");
  var $inputPieces = $(".input-number-pieces input");

  $inputPieces.keyup(function () {
    var minPieces = parseInt($inputPieces.attr("min"));
    var maxPieces = parseInt($inputPieces.attr("max"));
    var valuePieces = parseInt($inputPieces.val());

    $addPieces.prop("disabled", false);
    $removePieces.prop("disabled", false);

    if (valuePieces < minPieces + 1 || $inputPieces.val().length === 0) {
      $inputPieces.val(minPieces);
      $removePieces.prop("disabled", true);
    }

    if (valuePieces > maxPieces - 1) {
      $inputPieces.val(maxPieces);
      $addPieces.prop("disabled", true);
    }
  });

  $(".button-add-pieces").on("click", function () {
    changePieces("add");
  });

  $(".button-remove-pieces").on("click", function () {
    changePieces("remove");
  });
});
