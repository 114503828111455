!(function (t, n) {
  var a = {},
    e = function (t) {
      a[t] && (n.clearInterval(a[t]), (a[t] = null));
    },
    r = "waitUntilExists.found";
  t.fn.waitUntilExists = function (i, o, l) {
    var s = this.selector,
      u = t(s),
      c = u.not(function () {
        return t(this).data(r);
      });
    return (
      "remove" === i
        ? e(s)
        : (c.each(i).data(r, !0),
          o && u.length
            ? e(s)
            : l ||
              (a[s] = n.setInterval(function () {
                u.waitUntilExists(i, o, !0);
              }, 500))),
      u
    );
  };
})(window.jQuery, window);
