function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

$(document).ready(function () {
  var $name = $("#newsletter-name"),
    $email = $("#newsletter-email"),
    $checkBox = $(".input-check-newsletter"),
    $button = $(".button-submit-newsletter"),
    $newsletterAlert = $("#message-newsletter"),
    $newsletterClose = $(".message-newsletter-close"),
    $newsletterMessage = $(".newstletter-notification", $newsletterAlert),
    enabledForm = function () {
      $name.removeAttr("disabled");
      $email.removeAttr("disabled");
      $checkBox.removeAttr("disabled");
      $button.removeAttr("disabled");
    },
    disabledForm = function () {
      $name.prop("disabled", "disabled");
      $email.prop("disabled", "disabled");
      $checkBox.prop("disabled", "disabled");
      $button.prop("disabled", "disabled");
    },
    closeAlert = function () {
      $("span", $button).remove();
      $button.text("Cadastrar");

      enabledForm();

      setTimeout(function () {
        $newsletterAlert.attr("class", "message-newsletter hidden");
        $newsletterMessage.text("");
      }, 6000);
    };

  $email.on("input", function () {
    var $el = $(this),
      value = $el.val();

    if (isEmail(value)) {
      $checkBox.removeAttr("disabled");
    } else {
      $checkBox.prop("disabled", "disabled");
    }
  });

  $checkBox.on("click", function () {
    var $el = $(this),
      $currentValue = $el.prop("checked");

    if ($currentValue) {
      $button.removeAttr("disabled");
    } else {
      $button.prop("disabled", "disabled");
    }
  });

  $newsletterClose.on("click", function () {
    $newsletterAlert.attr("class", "message-newsletter hidden");
    $newsletterMessage.text("");
  });

  $(".newsletter-form").on("submit", function (e) {
    e.preventDefault();
    $button.html('Cadastrando <span class="spinner"></span>');
    disabledForm();

    var data = {
        name: $name.val(),
        email: $email.val(),
      },
      url =
        document.location.protocol +
        "//" +
        document.location.hostname +
        "/api/email-marketing/novo";

    $.ajax({
      data: data,
      url: url,
      type: "POST",

      success: function (response) {
        $newsletterAlert.addClass("success start-loading");
        $newsletterMessage.text(response[1]);
        $(".fa-icon", $newsletterAlert).attr("class", "fa fa-check-circle-o");
        $newsletterAlert.removeClass("hidden");

        closeAlert();

        $name.val("");
        $email.val("");
        $checkBox.prop("disabled", "disabled");
        $button.prop("disabled", "disabled");
      },

      fail: function (response) {
        $newsletterAlert.addClass("error start-loading");
        $newsletterMessage.text(response[1]);
        $(".fa-icon", $newsletterAlert).attr(
          "class",
          "fa fa-exclamation-circle"
        );
        $newsletterAlert.removeClass("hidden");

        closeAlert();
      },

      complete: function (response) {
        if (
          response.statusText === "Unprocessable Entity" ||
          response.responseJSON.email[0] === "O e-mail já está cadastrado"
        ) {
          var error = JSON.parse(response.responseText);

          $newsletterAlert.addClass("error start-loading");
          $newsletterMessage.text(error.email[0]);

          $(".fa-icon", $newsletterAlert).attr(
            "class",
            "fa fa-exclamation-circle"
          );

          $newsletterAlert.removeClass("hidden");
        }

        closeAlert();
      },
    });
  });
});
