$(document).ready(function () {

  $(".showcases").owlCarousel({
    loop: false,
    nav: true,
    dots: false,
    autoplay: false,
    margin: 15,
    items: 4
  });

  $(".showcases-mobile").owlCarousel({
    loop: false,
    nav: false,
    dots: true,
    autoplay: true,
    margin: 16,
    responsive: {
      0: { items: 1 },
      520: { items: 3 },
    },
  });

  $(".gallery-mobile img").waitUntilExists(function () {
    $(".gallery-mobile").owlCarousel({
      loop: false,
      nav: true,
      dots: true,
      autoplay: false,
      items: 1,
    });
  });


  $("#carousel-area-2").owlCarousel({
    loop: false,
    nav: false,
    dots: false,
    autoplay: false,
    margin: 16,
    items:4
  });

  $("#carousel-area-3").owlCarousel({
    loop: false,
    nav: false,
    dots: false,
    autoplay: false,
    items:1
  });

  if($("#carousel-categories .carousel-category-item").length >= 10) {
    $("#carousel-categories").addClass('owl-carousel').addClass('owl-theme');
    $("#carousel-categories").owlCarousel({
      loop: false,
      nav: false,
      dots: false,
      autoplay: false,
      center: false,
      items: 10
    });
  }

});
