$(document).ready(function () {
  $(".menu-mobile, .button-filter-mobile").on("click", function () {
    $("html").addClass("menu-mobile-html");
  });

  $(".close-menu, .menu-out-click").on("click", function () {
    $("html").removeClass("menu-mobile-html");
    $(".shop-area-sidebar, .categories-menu").hide();
    $("html, body").removeAttr("style");
  });

  $(".button-filter-mobile").on("click", function () {
    $(".shop-area-sidebar").show();
  });

  $(".menu-mobile-category span").on("click", function () {
    $(this).next().toggle(300);
  });
});
