$(document).ready(function () {
  var $headerContent = $(".header-content"),
    $menu = $(".menu", $headerContent),
    $firtSubMenu = $(".menu-first-sub", $menu),
    menuPosition = $menu.position();

  $firtSubMenu.css({ paddingLeft: menuPosition.left });

  $menu.hover(
    function () {
      $headerContent.addClass("menu-open");
      $('.page-nav-left').css('margin-top','84px');
    },
    function () {
      $headerContent.removeClass("menu-open");
      $('.page-nav-left').css('margin-top','58px');
    }
  );

  // $(window).on("resize", function () {
  //   console.log("POSITION: ", $(".menu").position().left);
  //   // $firtSubMenu.css({ paddingLeft: $(".menu").position().left });
  // });
});
